// import Vue from "vue";
import axios from "axios";

const SERVER_URL = "https://api.tuisyen.org/api/";
// const SERVER_URL = "http://localhost:4070/api/";
const instance = axios.create({
    baseURL: SERVER_URL,
    json: true

});

export default {

    async execute (method, resource, data, config) {
        // let accessToken = localStorage.getItem('token')
        return instance({
            method: method,
            url: resource,
            data,
            // headers: {
            //     Authorization: `${accessToken}`
            // },
            ...config
        });
    },

    createteacher (teacher) {
        // console.log("sending");
        return this.execute("POST", "candidates", teacher);
    }
}
