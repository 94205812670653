import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/components/Login";
import Signup from "@/components/Signup";
import Teacher_Form from "@/views/Teacher_Form";
import Admin_Form from "@/views/Admin_Form";
import Driver_Form from "@/views/Driver_Form";
import Homework_Teacher_Form from "@/views/Homework_Teacher_Form";

import ThankYouPage from "@/views/ThankYouPage";
// import store from "@/store";

import Jobs from "@/views/Jobs";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "",
      name: "Home",
      component: Home,
      // beforeEnter: (to, from, next) => {
      //   if (store.state.auth.user != null) {
      //     next();
      //     // } else next("/login");
      //   } else next("/teacher_form");
      // }
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/signup",
      name: "Signup",
      component: Signup
    },
    {
      path: "/jobs",
      name: "jobs",
      component: Jobs,
      props: true
    },

    {
      path: "/teacher-form",
      name: "Teacher_Form",
      component: Teacher_Form
    },
    {
      path: "/admin-form",
      name: "Admin_Form",
      component: Admin_Form
    },
    {
      path: "/homework-teacher-form",
      name: "Homework_Teacher_Form",
      component: Homework_Teacher_Form
    },
    {
      path: "/driver-form",
      name: "Driver_Form",
      component: Driver_Form
    },
    {
      path: "/thankyou",
      name: "ThankYouPage",
      component: ThankYouPage
    }
  ]
});
