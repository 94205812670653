import { render, staticRenderFns } from "./Admin_Form.vue?vue&type=template&id=3a39e834&"
import script from "./Admin_Form.vue?vue&type=script&lang=js&"
export * from "./Admin_Form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Admin_Form.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VContainer,VDatePicker,VFlex,VForm,VLayout,VMenu,VRadio,VRadioGroup,VSelect,VStepper,VStepperContent,VStepperStep,VTextField})
