import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";

import APIClient from "../apiClient";

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    lang: "en",
    teacher: {},
  },
  mutations: {
    changeLang (state, language) {
      state.lang = language;
    },
    updateTeacher (state, teacher) {
      state.teacher = teacher;
    },
  },
  actions: {
    sendTeacherData ({ commit }, teacher) {
      return APIClient.createteacher(teacher).then((Response) => {
        commit("updateTeacher", Response);
        // console.log(homeworkResponse);
      });

    },
  },
});