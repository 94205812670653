<i18n>
{
  "en": {

    "info": "Your Information",
    "speak-chinese":"Do you speack Chinese?",
    "read-chinese":"Can you read/write in Chinese?",
    "address": "Your Address",
    "expectation": "Job Expectations",
    "Firstname" : "Firstname",
    "Lastname": "Lastname",
    "ChineseName": "Chinese Name",
    "email": "E-mail",
    "gender": "Gender",
    "mobile": "Mobile",
    "birthday": "Birthday",
    "previous": "Previous",
    "continue": "Continue",
    "taman": "Taman",
    "state": "State",
    "availability": "Work Availability",
    "languageProficiency": "Language Proficiency",
    "noKnowledge": "No knowledge",
    "veryProficient": "Very Proficient",
    "malayLang": "Malay Language",
    "englishLang": "English Language",
    "chineseLang": "Chinese Language",
    "teachingStrength":"Your Teaching Strength",
    "strengthMalay": "Malay",
    "strengthEnglish": "English",
    "strengthChinese": "Chinese",
    "strengthMathematic": "Mathematic",
    "strengthScience": "Science",
    "Education": "Education",
    "computerSkills": "Computer Skills",
    "workExperience":"Work Experience Years - Indicate if any",
    "termWork": "Term of work",
    "partTime": "Part Time",
    "fullTime": "Full Time",
    "expectedSalary": "Expected Salary",
    "timeTeaching":"Time of Work",
    "submit":"Submit",
    "transport": "Do you have transport?",
    "moelicence": "Do you have a MOE teaching licence?",
    "namerulesEmpty": "Name is required",
    "namerulesLenght": "Name must be less than 12 characters",
    "namerulesEmailRequired": "E-mail is required",
    "namerulesEmailValid": "E-mail must be valid"
  },
    "zh": {
    "info": "你的资料",
    "speak-chinese":"你会说中文吗？",
    "read-chinese":"你会说中文吗？",
    "address": "你的地址",
    "expectation": "工作期望",
    "Firstname" : "名字",
    "Lastname": "姓",
    "ChineseName": "中文名",
    "email": "电子邮件",
    "gender": "性别",
    "mobile": "移动电话",
    "birthday": "生日",
    "previous": "以前",
    "continue": "继续",
    "taman": "小城 (Taman)",
    "state": "州",
    "availability": "工作可用性",
    "languageProficiency": "语言能力",
    "noKnowledge": "没有知识",
    "veryProficient": "非常精通",
    "malayLang": "马来语",
    "englishLang": "英语",
    "chineseLang": "中文",
    "teachingStrength":"你的教学实力",
    "strengthMalay": "马来语",
    "strengthEnglish": "英语",
    "strengthChinese": "中文",
    "strengthMathematic": "数学",
    "strengthScience": "科学",
    "Education": "教育",
    "computerSkills": "计算机技能",
    "workExperience":"工作经验年 - 表明是否有",
    "termWork": "工作期限",
    "partTime": "兼职",
    "fullTime": "全职",
    "expectedSalary": "期望薪水",
    "timeTeaching":"工作时间",
    "submit":"提交",
    "transport": "你有交通工具吗？",
    "moelicence": "你有教育教学许可吗？",
    "namerulesEmpty": "字段不能为空",
    "namerulesLenght": "名称必须少于12个字符",
    "namerulesEmailRequired": "需要电子邮件",
    "namerulesEmailValid": "电子邮件必须有效"
  }
}
</i18n>

<template>
  <v-container grid-list-md>
    <v-stepper v-model="step" vertical>
      <v-stepper-step step="1" :complete="step > 1">
        {{ $t("info") }}
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-form ref="teacherFormStep1" v-model="validStep1">
          <v-layout row wrap>
            <v-flex xs12 md8 lg4>
              <v-text-field
                v-model="teacher_data.firstname"
                :counter="15"
                :rules="rules.nameRules"
                :label="`${$t('Firstname')}`"
              ></v-text-field>
              <v-text-field
                v-model="teacher_data.lastname"
                :counter="15"
                :rules="rules.nameRules"
                :label="`${$t('Lastname')}`"
              ></v-text-field>

              <v-text-field
                v-model="teacher_data.chinesename"
                :counter="15"
                :rules="rules.anyNameFiled"
                :label="`${$t('ChineseName')}`"
              ></v-text-field>

              <v-text-field
                v-model="teacher_data.email"
                :rules="rules.emailRules"
                :label="`${$t('email')}`"
                required
              ></v-text-field>

              <v-select
                v-model="teacher_data.gender"
                :items="gender_items"
                :label="`${$t('gender')}`"
                :rules="rules.fieldRequired"
              ></v-select>

              <v-text-field
                v-model="teacher_data.cellphone"
                prepend-icon="mdi-cellphone-basic"
                :label="`${$t('mobile')}`"
                placeholder="6011-333-3333"
                :rules="rules.phonetest"
                required
              ></v-text-field>

              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="teacher_data.birthday"
                    :label="`${$t('birthday')}`"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :rules="rules.fieldRequired"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="teacher_data.birthday"
                  :max="
                    new Date(new Date().getFullYear() - 15, 1, 1)
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>

              <v-btn small color="primary" @click="validateStep1()">
                {{ $t("continue") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-stepper-content>
      <v-stepper-step step="2" :complete="step > 2">
        {{ $t("address") }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-form ref="teacherFormStep2" v-model="validStep2">
          <v-layout row wrap>
            <v-flex xs12 md6 lg6>
              <v-text-field
                v-model="teacher_data.address.taman"
                :counter="25"
                :label="`${$t('taman')}`"
                :rules="rules.fieldRequired"
                required
              ></v-text-field>
            </v-flex>
            <v-flex md6 lg6></v-flex>
            <v-flex xs12 md6 lg6>
              <v-select
                v-model="teacher_data.address.state"
                :items="states_items"
                :label="`${$t('state')}`"
                :rules="rules.fieldRequired"
                required
              ></v-select>
            </v-flex>
            <v-flex md6 lg6></v-flex>

            <v-flex xs12 md6 lg6>
              <v-select
                v-model="teacher_data.speakchinese"
                :items="speack_read_write_chinese_items"
                :label="`${$t('speak-chinese')}`"
                :rules="rules.fieldRequired"
                required
              ></v-select>
            </v-flex>
            <v-flex md6 lg6></v-flex>

            <v-flex xs12 md6 lg6>
              <v-select
                v-model="teacher_data.readwritechinese"
                :items="speack_read_write_chinese_items"
                :label="`${$t('read-chinese')}`"
                :rules="rules.fieldRequired"
                required
              ></v-select>
            </v-flex>
            <v-flex md6 lg6></v-flex>

            <v-flex xs12 md6 lg6>
              <v-btn small text @click="step = 1">{{ $t("previous") }}</v-btn>
              <v-btn small color="primary" @click="validateStep2()">
                {{ $t("continue") }}
              </v-btn>
              <v-flex md6 lg6></v-flex>
            </v-flex>
          </v-layout>
        </v-form>
      </v-stepper-content>
      <v-stepper-step step="3">{{ $t("expectation") }}</v-stepper-step>

      <v-stepper-content step="3">
        <v-form ref="teacherFormStep3" v-model="validStep3">
          <v-layout row wrap>
            <v-flex xs12 md8 lg4>
              <v-menu
                ref="menu_avaibility"
                v-model="menu_avaibility"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="teacher_data.availability"
                    :label="`${$t('availability')}`"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :rules="rules.fieldRequired"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="teacher_data.availability"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 6,
                      1
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  @change="save_Availability"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex md4 lg8></v-flex>

            <v-flex xs12 md4 lg6>
              <v-select
                v-model="teacher_data.education"
                :items="education_items"
                :label="`${$t('Education')}`"
                :rules="rules.fieldRequired"
                required
              ></v-select>
            </v-flex>

            <v-flex xs12 md4 lg6>
              <v-select
                v-model="teacher_data.computerskills"
                :items="computer_items"
                :label="`${$t('computerSkills')}`"
                :rules="rules.fieldRequired"
                required
              ></v-select>
            </v-flex>

            <v-flex xs12 md6 lg4>
              <v-select
                v-model="teacher_data.transport"
                :items="mybool_selection"
                :label="`${$t('transport')}`"
                required
              ></v-select>
            </v-flex>

            <v-flex xs12 md4 lg4>
              <v-select
                v-model="teacher_data.workexperience"
                :items="work_experience_items"
                :label="`${$t('workExperience')}`"
                required
              ></v-select>
            </v-flex>
            <v-flex md8 lg8></v-flex>
            <v-flex xs12 md4 lg4>
              <b>{{ $t("termWork") }}</b>
            </v-flex>
            <v-flex md8 lg8></v-flex>
            <v-flex xs12 md8 lg8>
              <v-radio-group
                v-model="teacher_data.termwork"
                :rules="rules.termWorkSelection"
                :mandatory="true"
              >
                <v-radio
                  :label="`${$t('partTime')}`"
                  @change="termWorkSelected = true"
                  value="part_time"
                ></v-radio>
                <v-radio
                  :label="`${$t('fullTime')}`"
                  @change="termWorkSelected = true"
                  value="full_time"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex md4 lg4></v-flex>

            <v-flex xs12 md4 lg4 v-if="teacher_data.termwork == 'full_time'">
              <v-text-field
                v-model="teacher_data.monthlysalary"
                :label="`${$t('expectedSalary')}`"
                suffix="Rm/Month"
                :rules="rules.fieldRequired"
                required
              ></v-text-field>
            </v-flex>
            <v-flex
              md8
              lg8
              v-if="teacher_data.termwork == 'full_time'"
            ></v-flex>

            <v-flex xs12 md4 lg4 v-if="teacher_data.termwork == 'part_time'">
              <v-text-field
                v-model="teacher_data.hoursalary"
                :label="`${$t('expectedSalary')}`"
                suffix="Rm/Hour"
                :rules="rules.fieldRequired"
                required
              ></v-text-field>
            </v-flex>
            <v-flex
              md8
              lg8
              v-if="teacher_data.termwork == 'part_time'"
            ></v-flex>

            <v-flex xs12 md4 lg4 v-if="teacher_data.termwork == 'part_time'">
              <v-select
                v-model="teacher_data.workingtime"
                :items="teaching_time_items"
                :label="`${$t('timeTeaching')}`"
                :rules="rules.fieldRequired"
                required
              ></v-select>
            </v-flex>
            <v-flex
              md8
              lg8
              v-if="teacher_data.termwork == 'part_time'"
            ></v-flex>

            <v-flex xs12 md12 lg12>
              <v-btn small text @click="step = 2">{{ $t("previous") }}</v-btn>
              <v-btn small color="primary" @click="submit_data">
                {{ $t("submit") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      teacher_data: {
        firstname: "",
        lastname: "",
        chinesename: "",
        email: "",
        gender: "",
        position: "",
        cellphone: "",
        birthday: null,
        education: "",
        address: {
          taman: "",
          state: "",
        },
        workingtime: "",
        termwork: "",
        speakchinese: "",
        readwritechinese: "",
        hoursalary: 0,
        monthlysalary: 0,
        workexperience: "",
        availability: null,
        computerskills: "",
        transport: "",
        teachinginfo: {
          malaylanguage: 0,
          englishlanguage: 0,
          chineselanguage: 0,
          moelicence: false,
          malaystrenght: false,
          englishstrenght: false,
          chinesestrenght: false,
          mathstrenght: false,
          sciencestrenght: false,
        },
      },
      step: 1,
      namedFiled: true,
      // registration: {},
      validStep1: false,

      validStep2: false,
      validStep3: false,
      menu: false,
      menu_avaibility: false,
      termWorkSelected: false,
      work_experience_items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      languageStrenghtItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      teaching_time_items: ["Any Time", "Morning", "Afternoon", "Evening"],
      speack_read_write_chinese_items: ["Yes", "No", "A Little"],
      transport_items: ["Yes", "No", "Maybe"],
      computer_items: ["None", "Beginner", "Intermediate", "Excellent"],
      education_items: ["SPM", "STPM", "Bachelor", "A Level/College", "Other"],
      gender_items: ["Female", "Male"],
      states_items: ["Johor", "Malacca", "Penang"],
      mybool_selection: [true, false],
      rules: {
        anyNameFiled: [
          (v) => v.length <= 15 || "Name must be less than 15 characters",
          () => {
            return this.namedFiled || "English or Chinese Name is Required";
          },
        ],
        termWorkSelection: [
          () => {
            return (
              this.termWorkSelected ||
              "Please Select Full Time or Part Time Work"
            );
          },
        ],
        fieldRequired: [(v) => !!v || "This Field is Required"],
        nameRules: [
          (v) => v.length <= 15 || "Name must be less than 15 characters",
        ],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        phonetest: [
          (value) => {
            const pattern = /^\(?([0-9]{4})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
            return pattern.test(value) || "Invalid Phone Number.";
          },
        ],
      },
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    menu_avaibility(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  created() {},
  methods: {
    checkLanguage() {
      if (this.$i18n.locale == "en") {
        this.teaching_time_items = [
          "Any Time",
          "Morning",
          "Afternoon",
          "Evening",
        ];
        this.transport_items = ["Yes", "No", "Maybe"];
        this.computer_items = ["None", "Beginner", "Intermediate", "Excellent"];
        this.education_items = [
          "SPM",
          "STPM",
          "Bachelor",
          "A Level/College",
          "Other",
        ];
        this.gender_items = ["Female", "Male"];
      } else {
        this.teaching_time_items = ["随时", "早上", "下午", "晚间"];
        this.transport_items = ["是", "没有", "也许"];
        this.computer_items = ["没有", "初学者", "中间", "优秀"];
        this.education_items = [
          "SPM",
          "STPM",
          "单身汉",
          "A Level/学院",
          "其他",
        ];
        this.gender_items = ["女", "男"];
      }
    },
    validateStep1() {
      this.namedFiled = false;
      if (
        this.teacher_data.chinese_name != "" ||
        (this.teacher_data.firstname != "" && this.teacher_data.lastname != "")
      ) {
        this.namedFiled = true;
      }
      this.$refs.teacherFormStep1.validate();
      if (this.$refs.teacherFormStep1.validate()) {
        this.step = 2;
      }
      this.checkLanguage();
    },
    validateStep2() {
      this.$refs.teacherFormStep2.validate();
      if (this.$refs.teacherFormStep2.validate()) {
        this.step = 3;
      }
      this.checkLanguage();
    },
    save(birthday) {
      this.$refs.menu.save(birthday);
    },
    save_Availability(availability) {
      this.$refs.menu_avaibility.save(availability);
    },
    dismissError() {},

    submit_data() {
      if (this.teacher_data.termwork != "") {
        this.termWorkSelected = true;
      }
      this.$refs.teacherFormStep3.validate();
      if (this.$refs.teacherFormStep3.validate()) {
        let birthday = new Date(this.teacher_data.birthday);
        let availability = new Date(this.teacher_data.availability);
        let monthlysalary = Number(this.teacher_data.monthlysalary);
        let hoursalary = Number(this.teacher_data.hoursalary);
        this.teacher_data.position = this.$route.params.position;
        this.teacher_data.birthday = birthday;
        this.teacher_data.availability = availability;
        this.teacher_data.monthlysalary = monthlysalary;
        this.teacher_data.hoursalary = hoursalary;
        console.log(this.teacher_data);

        const data = {
          candidate: this.teacher_data,
        };

        this.$store
          .dispatch("sendTeacherData", data)
          .then(() => {
            this.$router.push({ path: "thankyou" });
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log(this.$store.state.teachers.ids);
      }
    },
  },
};
</script>
