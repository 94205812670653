<i18n>
{
  "en": {

    "apply": "Apply Here"
    },
    "zh": {
    "apply": "在这里申请"
    }
}
</i18n>

<template>
  <v-container grid-list-md text-xs-center pt-5 max-width="600">
    <div class="text-xs-left pb-3">
      <h2>Job Title: {{ title }}</h2>
    </div>

    <div v-for="(item, index) in mainHeader" :key="index">
      <v-layout v-if="index % 2 == 0" wrap grey lighten-2>
        <v-flex xs6 sm6 lg2 text-xs-left>
          <div class="black--text text--darken-2">{{ item }}</div>
        </v-flex>
        <v-flex xs6 sm6 text-xs-right>
          <div class="grey--text text--darken-2">
            {{ descriptionData[index] }}
          </div>
        </v-flex>
      </v-layout>

      <v-layout v-else wrap grey lighten-4>
        <v-flex xs6 sm6 lg2 text-xs-left>
          <div class="black--text text--darken-2">{{ item }}</div>
        </v-flex>
        <v-flex xs6 sm6 text-xs-right>
          <div class="grey--text text--darken-2">
            {{ descriptionData[index] }}
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div class="text-xs-center mt-4 pt-3">
      <v-btn rounded @click="sendToForm()">
        {{ $t("apply") }}
      </v-btn>
      <!-- <router-link to="Teacher_Form">
        <h3></h3>
      </router-link> -->
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import jobs from "../assets/data.json";
export default {
  name: "jobs",
  components: {},
  data() {
    return {
      queryTitle: "",
      title: "",
      mainHeader: [],
      jobs: null,
      descriptionData: [],

      headerEnglish: [
        "Company Location:",
        "Nature of Work:",
        "Working Hours:",
        "Working Days:",
        "Salary:",
        "Criteria:",
        "Benefits:",
        "Remarks:",
      ],
      headerChinese: [
        "公司所在地:",
        "工作性质:",
        "工作时间:",
        "工作日期:",
        "工钱:",
        "条件:",
        "福利:",
        "其他备注:",
      ],
    };
  },
  watch: {},
  computed: {
    language() {
      return this.$store.state.lang;
    },
  },
  methods: {
    buildData() {
      if (this.language == "en") {
        this.mainHeader = this.headerEnglish;

        if (this.queryTitle == "teacher") {
          this.title = "Tuition Teacher";
          return this.jobs.teacher.map((item) => {
            return item.data;
          });
        }

        if (this.queryTitle == "homework") {
          this.title = "Homework Teacher";
          return this.jobs.homework.map((item) => {
            return item.data;
          });
        }

        if (this.queryTitle == "admin") {
          this.title = "Admin/Clerk";
          return this.jobs.admin.map((item) => {
            return item.data;
          });
        }
      } else {
        this.mainHeader = this.headerChinese;
        if (this.queryTitle == "teacher") {
          this.title = "老师";
          return this.jobs.teacherChinese.map((item) => {
            return item.data;
          });
        }

        if (this.queryTitle == "homework") {
          this.title = "作业老师";
          return this.jobs.homeworkChinese.map((item) => {
            return item.data;
          });
        }

        if (this.queryTitle == "admin") {
          this.title = "管理员/文员";
          return this.jobs.adminChinese.map((item) => {
            return item.data;
          });
        }
      }
    },
    sendToForm() {
      if (this.queryTitle == "homework") {
        this.$router.push({
          name: "Homework_Teacher_Form",
          params: { position: "Homework Teacher" },
        });
      }
      if (this.queryTitle == "teacher") {
        this.$router.push({
          name: "Teacher_Form",
          params: { position: "Teacher" },
        });
      }
      if (this.queryTitle == "driver") {
        this.$router.push({
          name: "Driver_Form",
          params: { position: "Driver" },
        });
      }
      if (this.queryTitle == "admin") {
        this.$router.push({
          name: "Admin_Form",
          params: { position: "Admin/Clerk" },
        });
      }
    },
  },
  beforeUpdate() {
    this.descriptionData = this.buildData();
  },
  async mounted() {
    // this.locale = this.$i18n.locale;
    this.queryTitle = this.$route.query.title;

    this.jobs = await import("../store/data.json");
    this.descriptionData = this.buildData();
  },
};
</script>
