<template>
  <div class="main">
    <v-form ref="loginForm" v-model="valid">
      <v-container>
        <v-layout>
          <v-flex xs12 md4>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="`${$t('email')}`"
              required
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md4>
            <v-text-field
              v-model="password"
              type="password"
              :rules="nameRules"
              :label="`${$t('password')}`"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-layout>
        <v-flex xs12 md4>
          <v-btn @click="onSubmit">{{ $t("login") }}</v-btn>
          <!-- <v-btn @click="onSignup">Signup</v-btn> -->
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<i18n>
{
  "en": {
    "login": "Login",
    "email": "E-mail",
    "password": "Password",
    "namerulesEmpty": "Name is required",
    "namerulesLenght": "Name must be less than 12 characters",
    "namerulesEmailRequired": "E-mail is required",
    "namerulesEmailValid": "E-mail must be valid"
  },
    "zh": {
    "login": "登录",
    "email": "电子邮件",
    "password": "密码",
    "namerulesEmpty": "字段不能为空",
    "namerulesLenght": "名称必须少于12个字符",
    "namerulesEmailRequired": "需要电子邮件",
    "namerulesEmailValid": "电子邮件必须有效"
  }
}
</i18n>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      valid: false,
      nameRules: [
        v => !!v || this.$i18n.t("namerulesEmpty"),
        v => (v && v.length <= 12) || this.$i18n.t("namerulesLenght")
      ],
      email: "",
      emailRules: [
        v => !!v || this.$i18n.t("namerulesEmailRequired"),
        v => /.+@.+/.test(v) || this.$i18n.t("namerulesEmailValid")
      ],
      password: undefined,
      error: undefined
    };
  },
  watch: {},
  created() {},
  methods: {
    dismissError() {
      this.error = undefined;
      this.clearAuthenticateError();
    },
    onSignup() {
      this.$router.replace("/signup");
    },
    onSubmit() {
      this.$refs.loginForm.validate();
      if (this.valid) {
        this.authenticate({
          strategy: "local",
          email: this.email,
          password: this.password
        })
          // Just use the returned error instead of mapping it from the store.
          .catch(error => {
            // Convert the error to a plain object and add a message.
            console.log(error);
          });
      }
    },
    ...mapMutations("auth", {
      clearAuthenticateError: "clearAuthenticateError"
    }),
    ...mapActions("auth", ["authenticate"])
  }
};
</script>
