<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-toolbar>
          <v-toolbar-items class="sm-and-down">
            <!-- <v-btn flat small to="/">Home</v-btn> -->
            <!-- <v-btn flat small to="/teacher_form">Teacher Form</v-btn> -->
            <!-- <v-btn
              v-if="$store.state.auth.user != null"
              flat
              small
              to="/test_stepper"
              >Teacher Listing</v-btn
            > -->
            <!-- <v-btn
              v-if="$store.state.auth.user != null"
              flat
              small
              to="/test_feathers"
              >Testing Feathers</v-btn
            > -->
            <!-- <v-btn flat small to="/teacher_summary">Teacher Summary</v-btn> -->
          </v-toolbar-items>
          <v-spacer></v-spacer>

          <v-toolbar-items class="sm-and-down">
            <v-switch
              v-model="language"
              :label="language_selected"
              @change="changeLanguage"
            ></v-switch>
          </v-toolbar-items>
        </v-toolbar>
        <router-view />
        <Footer />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: {
    Footer,
  },
  name: "app",
  data() {
    return {
      language: false,
      language_selected: "中文",
    };
  },
  computed: {
    // The user is automatically set by the feathers-vuex auth module upon login.
    // user() {
    //   return this.$store.state.auth.user;
    // },
  },
  watch: {
    // When the user is set, redirect to the Chat page.
    // user(newVal) {
    //   if (newVal === undefined) {
    //     this.$router.replace({ name: "Login" });
    //   } else {
    //     this.$router.replace({ name: "Home" });
    //   }
    // },
  },
  methods: {
    changeLanguage() {
      this.language
        ? (this.language_selected = "English")
        : (this.language_selected = "中文");
      this.language ? (this.$i18n.locale = "zh") : (this.$i18n.locale = "en");
      this.$store.commit("changeLang", this.$i18n.locale);
    },
  },
  mounted() {
    // console.log(this.user);
    // this.$store.dispatch("auth/authenticate").catch((error) => {
    //   if (!error.message.includes("Could not find stored JWT")) {
    //     console.error(error);
    //   }
    // });
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* margin: 1em 1em 1em 1em; */
}
</style>